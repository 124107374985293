import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import { Dropdown } from "bootstrap";
export const Header = () => {
  return (
    <>
      <div class="sticky">
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className=" mb-3 Navbar-Custom"

            //   className=""
          >
            <Container fluid>
              <Navbar.Brand href="#">
                <img
                  src={require("../../Images/logo.png")}
                  width={240}
                  alt=""
                />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Offcanvas
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center align-items-center flex-grow-1 pe-md-3 pe-0">
                    <Nav.Link href="#action1">
                      <NavLink to="">Homes</NavLink>
                    </Nav.Link>

                    <Nav.Link
                      className="  position-relative ServicesDropDownContainer"
                      href="#action143"
                    >
                      <NavLink to="">Plots</NavLink>
                    </Nav.Link>

                    <Nav.Link
                      className="TradeDropDwonContainer"
                      href="#action68"
                    >
                      <NavLink to="">Commerical</NavLink>
                    </Nav.Link>

                    <Nav.Link href="#action6">
                      <NavLink to="">Rent</NavLink>
                    </Nav.Link>

                    <Nav.Link href="#action7">
                      <NavLink to="">Maps </NavLink>
                    </Nav.Link>

                    <Nav.Link href="#action8">
                      <NavLink to="">Area Guides</NavLink>
                    </Nav.Link>

                    <Nav.Link href="#action8">
                      <NavLink to="">Clients Portals</NavLink>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </>
  );
};
