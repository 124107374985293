import logo from "./logo.svg";
import { Header } from "./Components/Header/Header.jsx";
import "./App.css";
import { Route, Router, Routes } from "react-router-dom";
import CardSpotlight, { TestComponent } from "./Components/CardSpotlight";
import { useState, MouseEvent, useCallback } from "react";
import { Home } from "./Components/Home/Home.jsx";
function App() {
  return (
    <div className="App">
      <Header />

      <span className="inline-flex animate-background-shine bg-[linear-gradient(110deg,#EBB208,45%,#1e293b,55%,#939393)] bg-[length:250%_100%] bg-clip-text text-xl text-transparent">
        Sample Text
      </span>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
