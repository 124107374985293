import React from "react";
import "./Home.css";
export const Home = () => {
  return (
    <div>
      <div className=" bgContainer ">
        <div className=" innercontainer">
          <p
            style={{ paddingTop: "50px" }}
            className="inline-flex fw-semibold animate-background-shine bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] text-6xl bg-clip-text  text-transparent"
          >
            We Will be Live Soon
          </p>
          <p
            // style={{ color: "white", fontWeight: "600px", fontSize: "20px" }}
            className=" fw-semibold  fw-semibold animate-background-shine bg-[linear-gradient(110deg,white,45%,black,55%,white)] bg-[length:250%_100%] text-3xl bg-clip-text  text-transparent"
          >
            {" "}
            FOLLOW US:{" "}
            <a
              className=" text-decoration-none"
              target="blank"
              href=" https://www.facebook.com/people/MF-Real-Estate-Holdings/61563465384057/"
            >
              {" "}
              <img
                src={require("../../Images/Fb.png")}
                width={40}
                className=" d-inline facebook"
                alt=""
              />{" "}
            </a>{" "}
            <a
              className=" text-decoration-none"
              target="blank"
              href="https://www.instagram.com/mfrealestateholdings"
            >
              {" "}
              <img
                src={require("../../Images/insta.png")}
                width={40}
                className=" d-inline"
                alt=""
              />{" "}
            </a>{" "}
            <a
              className=" text-decoration-none"
              target="blank"
              href="https://www.linkedin.com/company/mf-real-estate-holdings/"
            >
              {" "}
              <img
                src={require("../../Images/linkedin.png")}
                width={40}
                className=" d-inline"
                alt=""
              />{" "}
            </a>{" "}
          </p>
          <div style={{ justifyContent: "center" }} className=" d-flex ">
            <a
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "600",
                fontSize: "25px",
                margin: "0px 20px",
              }}
              href="tel:+1 718 717 8306"
            >
              {" "}
              +1 718 717 8306
            </a>
            <a
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "600",
                fontSize: "25px",
              }}
              href="mailto:Muhammad@mfbzone.com"
            >
              Muhammad@mfbzone.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
